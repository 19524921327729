// Module
var code = `<template>
  <require from="./contact_us.scss"></require>
  <require from="../../../components/loader-ring/loader-ring"></require>
  <section id="contact_us" class="flex-column">
    <!-- <div id="miniheader" class="flex-row miniheader">
      <img
        id="thumbnail"
        click.delegate="router.navigateBack()"
        src="/static/img/arrow_left.svg"
      />
      <div class="flex-grow"></div>
      <h1 i18n="profile.contact_us.title"></h1>
      <div class="flex-grow"></div>
    </div> -->
    <form class="inputs flex-column" id="suggestionForm" class.bind="shaking ? 'shake' : ''">
      <div>
        <input id="profile.contact_us.input.subject" value.bind="title"  i18n="[placeholder]profile.contact_us.subject" required></input>
      </div>
      <textarea value.bind="text" i18n="[placeholder]profile.contact_us.text" required></textarea>
      <div>
        <input id="profile.auth.input.email"value.bind="email"  i18n="[placeholder]auth.email" required></input>
      </div>
    </form>
    <div class="flex-grow"></div>
    <div class='error' class.bind="shaking ? 'shake' : ''" if.bind='displayError' style="background: none; flex:1">
      <span>
        \${errorMessage}
      </span>
    </div>
    <div class="flex-grow"></div>
    <button  id="profile.contact_us.button.sendPostReportCall" class="btn btn-primary" form="suggestionForm" if.bind="title && text" click.delegate="sendPostReportCall()">
       <loader-ring class="loader-btn" if.bind="isLoading"></loader-ring>
      <span if.bind="!isLoading">Envoyer</span>
    </button>
    <button id="profile.contact_us.button.fakeDisabled" class="btn btn-primary fakeDisabled" form="suggestionForm" if.bind="!title  || !text"
      click.delegate="shakeError()">
       <loader-ring class="loader-btn" if.bind="isLoading"></loader-ring>
      <span i18n="profile.send"></span>
    </button>
  </section>
</template>
`;
// Exports
export default code;